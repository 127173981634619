import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {LoaderComponent} from './components/loader/loader.component';
import {AuthGuard, UnAuthGuard} from './guards/auth.guard';

const MODULES = [
  CommonModule,
  FormsModule,
  IonicModule,
  TranslateModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    ...MODULES,
  ],
  providers: [
    AuthGuard,
    UnAuthGuard
  ],
  exports: [
    ...MODULES,
    LoaderComponent
  ],
})
export class SharedModule {}
