import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AuthState} from '../../auth/store/auth.state';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AppState} from '../../store/app.state';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.store.select('auth')
      .pipe(
        map((auth: AuthState) => {
          const test = !!(auth != null && auth !== undefined && auth.user);
          if (!test) {
            this.router.navigate(['/auth/sign-in']);
          }
          return test;
        })
      );
  }
}

@Injectable()
export class UnAuthGuard implements CanActivate {
  constructor(private store: Store<AppState>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.select('auth')
      .pipe(
        map((auth: AuthState) => {
          return !!(auth == null || auth === undefined || !auth.user);
        })
      );
  }
}
